import React, { useState } from 'react';
import styled from 'styled-components';
import TvImage1 from '../../../assets/images/404-tv-1.png';
import TvImage2 from '../../../assets/images/404-tv-2.png';
import Floater from '../../../components/Floater';
import { useInterval } from '../../../hooks';

const TIMES = [2000, 75, 75, 150, 50];

const StyledImageWrapper = styled.div(
  ({ theme: { mobileView } }) => `
  width: 100%;
  margin: 0 auto; 
  ${mobileView} {
    img {
      width: 40%;
      height: 40%;
      margin: 0 auto; 
    } 
  }

  
  img:hover {
    transform: scale(1.2);
    transition: transform 3s;
  }
`,
);

const Moon: React.StatelessComponent = () => {
  const [image, setImage] = useState(TvImage1);
  const [timeIndex, setTimeInterval] = useState(0);

  useInterval(() => {
    setImage(image === TvImage1 ? TvImage2 : TvImage1);
    setTimeInterval(timeIndex < TIMES.length ? timeIndex + 1 : 0);
  }, TIMES[timeIndex]);

  return (
    <Floater>
      <StyledImageWrapper>
        <img src={image} />
      </StyledImageWrapper>
    </Floater>
  );
};

export default Moon;
