import React from 'react';
import SplatterImage from '../../../assets/images/paint-splatter-green.png';
import StyledGreeble from './style';
import { WidthHeightGreebleType } from './types';

const GreenSplatter5: React.StatelessComponent<WidthHeightGreebleType> = ({ top, bottom }) => (
  <StyledGreeble top={top || -4} bottom={bottom} right={-4} width={45} height={45} image={SplatterImage} />
);

export default GreenSplatter5;
