import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Background from '../assets/images/bg-countdown.png';
import TakeMeHomeTv from '../components/CSSParallax/Greeblies/TakeMeHomeTv';
import BlueSplatter from '../components/CSSParallax/Greeblies/BlueSplatter2';
import GreenSplatter from '../components/CSSParallax/Greeblies/GreenSplatter6';
import Moon from '../components/CSSParallax/Greeblies/Moon';
import PinkSplatter from '../components/CSSParallax/Greeblies/PinkSplatter1';
import WhiteSplatter from '../components/CSSParallax/Greeblies/WhiteSplatter6';
import MinimalLayout from '../layout/Minimal';
import isBrowser from '../utils/isBrowser';

const Page = styled.div(
  ({ pageHeight = '100vh' }: { pageHeight?: string }) => `
  height: ${pageHeight};
  width: 100%
  display: block;
  position: relative;
  overflow: hidden;
  z-index: 0;

  &::after {
    content: "";
    background-image: url(${Background});
    background-repeat: round;
    overflow: hidden;
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
`,
);

const ContentWrapper = styled.div(
  () => `
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`,
);

const MoonsWrapper = styled.div`
  position: absolute;
  top: calc(50% - (32vw / 2));
  right: 15vw;
  z-index: 2;
`;

function useWindowHeight() {
  function getHeight() {
    return isBrowser() && `${window.innerHeight}px`;
  }

  const [windowHeight, setWindowHeight] = useState(getHeight);

  useEffect(() => {
    if (!isBrowser()) {
      return;
    }

    function handleResize() {
      setWindowHeight(getHeight());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowHeight;
}

export const CountdownPage: React.StatelessComponent<any> = () => {
  const pageHeight = useWindowHeight();

  return (
    <MinimalLayout>
      <Page pageHeight={pageHeight}>
        <MoonsWrapper>
          <Moon size={21} top={0} />
          <Moon size={10} top={20} right={-2} />
        </MoonsWrapper>
        <GreenSplatter />
        <PinkSplatter bottom={-30} right={0} />
        <BlueSplatter top={30} left={0} flipX={true} />
        <WhiteSplatter top={-22} />
        <ContentWrapper>
          <a href="/">
            <TakeMeHomeTv />
          </a>
        </ContentWrapper>
      </Page>
    </MinimalLayout>
  );
};

export default CountdownPage;
