import React from 'react';
import SplatterImage from '../../../assets/images/paint-splatter-white-2.png';
import StyledGreeble from './style';
import { WidthHeightGreebleType } from './types';

const WhiteSplatter6: React.StatelessComponent<WidthHeightGreebleType> = ({ top, bottom }) => (
  <StyledGreeble
    top={top}
    left={0}
    flipX={false}
    flipY={true}
    rotation={90}
    bottom={bottom}
    width={30.0}
    height={30.0}
    image={SplatterImage}
  />
);

export default WhiteSplatter6;
